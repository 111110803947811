import { BaseApiResponse } from '@/models/generics';

export type ProfileMenuApiResponseData = {
  menu: MenuItem[];
};

export enum MenuItemNames {
  CONTACT_US = 'CONTACT_US',
  HELP_CENTER = 'HELP_CENTER',
  LOYALTY = 'LOYALTY',
  WISHLIST = 'WISHLIST',
  REFERRALS = 'REFERRALS',
  DELETE_PROFIL = 'DELETE_PROFILEE',
  PAYMENTS = 'PAYMENTS',
  SUBSCRIPTION = 'SUBSCRIPTION',
  ADDRESSES = 'ADDRESSES',
  ORDERS = 'ORDERS',
}

export type MenuItem = {
  name: MenuItemNames;
  data: {
    deeplink: string;
  };
  title: string;
};

export type ProfileMenuApiResponse = BaseApiResponse<ProfileMenuApiResponseData>;