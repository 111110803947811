import Hoverable from '@/atomic-components/Hoverable';
// import HelpCenterIcon from '@/assets/profile/help-center.svg';
import ContactUsHoveredIcon from '@/assets/profile/contact-us-hovered.svg';
import { LencoTypography } from '@/atomic-components/LencoTypography';

export type MenuItemProps = {
	active: boolean;
};

const ContactUsMenuItem = (props: MenuItemProps) => {
	return (
		<Hoverable
			icons={{
				before: <ContactUsHoveredIcon />,
				after: <ContactUsHoveredIcon />,
			}}
			sx={{ display: 'flex', flexDirection: 'row' }}
			active={props.active}
		>
			<LencoTypography ml='16px'>Contact Us</LencoTypography>
		</Hoverable>
	);
};

export default ContactUsMenuItem;
